<template>
  <div class="accountsecurity" id="accountsecurity">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Account Security</p>
        <h3><span>Account</span> Security</h3>
        <h4 id="accsec1">How do I make sure that my TayoCash Wallet is safe?</h4>
        <div class="paragraph">
          <p>
            You can be assured of the safety and security of your TayoCash Wallet as it complies with the requirements and regulations of the Bangko Sentral ng Pilipinas (BSP) and Anti-Money Laundering Council (AMLC).
          </p>
          <p>
            To ensure your utmost security, we also provided the following additional safety features to your TayoCash Wallet:
          </p>

          <ol class="violet-text">
            <li><span class="vtxt">
              Nominate a 4-digit MPIN for your TayoCash app.
            </span>
                Upon registration in the TayoCash app, you will be asked to nominate a 4-digit MPIN. You will be required to input this 4-digit MPIN every
                time you open your TayoCash app.
              <span>
                  Make sure to keep your MPIN private and to never share it with others.
              </span>
            </li>
            <li>
              <span class="vtxt">
                Enable the Two-Factor Authentication feature of your mobile device.
              </span>
              After nominating your 4-digit MPIN, you will be prompted to enable authentication such as your Touch ID or facial recognition. Once
              enabled, this ensures a higher level of security in opening your TayoCash app.
            </li>
          </ol>
        </div>

        <h4 id="accsec2">I lost my phone/SIM that’s linked to my TayoCash account.  What do I do?</h4>
        <div class="paragraph">
          <p>
            You may contact TayoCash Customer Care by emailing <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a> or calling the Service Hotline at <span class="vtxt-align">8888-TAYO</span>. You may also <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> on the website to report the loss.
          </p>
          <p>
            Once we receive your request, we will temporarily suspend your TayoCash account within <span class="vtxt-align">48 hours</span>. We will continue to suspend your account beyond <span class="vtxt-align">48 hours</span> pending the submission of the following documents via email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:

            <ul>
              <li>Notarized Affidavit of Loss</li>
              <li>Government-Issued ID</li>
              <li>Selfie with Government-Issued ID</li>
              <li>Other supporting documents which may be required</li>
            </ul>
          </p>
          <p>
            A Customer Care Representative will assist you upon receipt of your request.
          </p>
        </div>

        <h4 id="accsec3">
          I now have a new SIM/Mobile number. How do I request to transfer the funds from my old account to my new TayoCash account?
        </h4>
        <div class="paragraph">
          <p>
            If you choose to use a different mobile number or SIM for your TayoCash account, you will need to register the new mobile number and go through the usual verification steps.
          </p>
          <p>
            Kindly email the following documents to TayoCash’s Customer Care Group at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a> to proceed with the wallet balance transfer to your new SIM:
            <ul>
              <li>Notarized Affidavit of Loss</li>
              <li>Government-Issued ID</li>
              <li>Selfie with Government-Issued ID</li>
              <li>Other supporting documents which may be required</li>
            </ul>
          </p>
          <p>
            Upon validation of all documents, you will be able to access the TayoCash app using your new mobile number.
          </p>
        </div>

        <h4 id="accsec4">I have other concerns regarding my account security. What do I do?</h4>
        <div class="paragraph">
          <ol>
            <li>
              <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link>
              via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
              <span class="nomargin">a. Select <span class="vtxt-align">Account Security</span></span>
              <span class="nomargin">b. Input the details of your concern in the text field</span>
            </li>
            <li>
              Send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:
              <span class="nomargin">a. Email Subject: Account Security</span>
              <span class="nomargin">b. Email Content: Details of your account security concern</span>
            </li>
          </ol>
          <p>
            A TayoCash Customer Care Representative will get back to you within 48 hours after you have submitted the ticket.
          </p>
        </div>

        <div class="buttons">
          <a href="/faq/deactivation" class="yellow">BACK</a>
          <a href="/faq/feesandcharges" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'accountsecurity'
}
</script>

<style scoped lang='scss'>
</style>
